<template>
  <b-alert
    v-model="this.src"
    :variant="this.variant"
    :class="{ 'alert--top': this.top, 'alert--bottom': this.bottom }"
  >
    <div>
      <slot></slot>
    </div>
    <i
      @click="closeAlert()"
      v-if="closable"
      class="icon-close-variant text-white ml-8"
    ></i>
  </b-alert>
</template>

<script>
export default {
  name: "AlertBox",
  data() {
    return {
      dismiss: this.closable,
    };
  },
  props: {
    src: Boolean,
    link: String,
    variant: String,
    position: String,
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: true,
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeAlert() {
        this.$emit('removeAlert');
    },
  },
};
</script>

<style lang="scss">
.alert {
  position: fixed;
  padding: rem(18px) rem(24px) rem(20px) rem(24px);
  z-index: 99;
  a {
    text-decoration: underline;
    font-size: 16px;
    text-decoration-color: transparent;
  }
  &-danger {
    background-color: rgba($red, 20%);
    a {
      color: $white;
      &:hover,
      &:focus,
      &:active {
        text-decoration-color: $white;
      }
    }
  }
  &-success {
    background-color: $green;
    a {
      color: $white;
      &:hover,
      &:focus,
      &:active {
        text-decoration-color: $white;
      }
    }
  }
  &-warning {
    background-color: $orange;
    a {
      color: $white;
      &:hover,
      &:focus,
      &:active {
        text-decoration-color: $white;
      }
    }
  }
  &-info {
    background-color: rgba($blue, 0.9);
    a {
      color: $white;
      &:hover,
      &:focus,
      &:active {
        text-decoration-color: $white;
      }
    }
  }
  &--top {
    top: 0;
    right: 0;
    bottom: unset !important;
    border-radius: 0 0 0 $border-radius !important;
  }
  &--bottom {
    border-radius: $border-radius 0 0 0;
    bottom: 0;
    right: 0;
  }
}
</style>
