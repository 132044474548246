var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { id: "infoModal", centered: "", "hide-footer": "", size: "lg" },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c(
                "div",
                { staticClass: " text-center", staticStyle: { width: "100%" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "d-block mt-auto float-left fs24 lh28 fw500"
                    },
                    [_vm._v(" LOW BALANCE ALERT ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { size: "sm", variant: "rounded" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [_c("i", { staticClass: "icon-close-variant" })]
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "div",
        { staticClass: "col pt-20 pt-md-20 row d-flex justify-content-around" },
        [
          _c(
            "div",
            { staticClass: "col-md-12 d-flex justify-content-around" },
            [
              _c("img", {
                staticStyle: { height: "3rem", width: "3rem" },
                attrs: {
                  src: require("@/assets/images/warninglow.png"),
                  alt: ""
                }
              })
            ]
          ),
          _c("p", { staticClass: "fs18 mt-15" }, [
            _c("strong", [_vm._v("Account Balance Low")])
          ]),
          _c("p", { staticClass: "mt-20 fs16" }, [
            _c("strong", [
              _vm._v(
                "Your Account Balance is low, Please Add Funds to Continue"
              )
            ])
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "col row d-flex justify-content-around pt-20" },
        [
          _c(
            "b-button",
            {
              staticClass: "btn-small d-none d-md-block",
              attrs: { variant: "primary" },
              on: { click: _vm.openAddfundsalert }
            },
            [
              _c("i", { staticClass: "icon-payment-method fs16 pr-8" }),
              _vm._v("ADD FUNDS")
            ]
          )
        ],
        1
      ),
      _c("BillingAddFunds", { ref: "BillingAddFunds" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }