var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    ref: "userAgreement",
    attrs: {
      centered: "",
      title: "User Agreement",
      id: "userAgreement",
      "modal-class": "pricing-modal",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function(ref) {
          var close = ref.close
          return [
            _c("span", { staticClass: "flex-fill fs18 lh28 fw500 mt-8" }, [
              _vm._v(" Agreement Update "),
              _c("span", { staticClass: "text-gray-600 fs12 float-right" }, [
                _vm._v("v" + _vm._s(_vm.agreement.version))
              ])
            ])
          ]
        }
      },
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "justify-content-between pt-8 pt-md-26" },
              [
                _c("p", {
                  domProps: { innerHTML: _vm._s(_vm.agreement.changes) }
                })
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function(ref) {
          var close = ref.close
          return [
            _c("div", [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "fs12 text-gray-600",
                      attrs: {
                        name: "checkbox-1",
                        value: "accepted",
                        disabled: _vm.accepted,
                        "unchecked-value": "not_accepted"
                      },
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    },
                    [
                      _vm._v(" I accept the "),
                      _c(
                        "b-link",
                        {
                          attrs: {
                            href: _vm.agreement.agreementPath,
                            target: "_blank"
                          }
                        },
                        [_vm._v("user agreement")]
                      ),
                      _vm._v(
                        ", updated on " +
                          _vm._s(_vm._f("date")(_vm.agreement.publishedDate)) +
                          " "
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  !_vm.accepted
                    ? _c(
                        "b-button",
                        {
                          staticClass: "btn-sm",
                          attrs: { variant: "primary", disabled: !_vm.status },
                          on: { click: _vm.accept }
                        },
                        [_c("span", [_vm._v("Accept")])]
                      )
                    : _c("span", { staticClass: "tag success" }, [
                        _vm._v("Accepted "),
                        _c("i", { staticClass: "icon-booked" })
                      ])
                ],
                1
              )
            ])
          ]
        }
      }
    ]),
    model: {
      value: _vm.isOpen,
      callback: function($$v) {
        _vm.isOpen = $$v
      },
      expression: "isOpen"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }