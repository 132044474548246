var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-alert",
    {
      class: { "alert--top": this.top, "alert--bottom": this.bottom },
      attrs: { variant: this.variant },
      model: {
        value: this.src,
        callback: function($$v) {
          _vm.$set(this, "src", $$v)
        },
        expression: "this.src"
      }
    },
    [
      _c("div", [_vm._t("default")], 2),
      _vm.closable
        ? _c("i", {
            staticClass: "icon-close-variant text-white ml-8",
            on: {
              click: function($event) {
                return _vm.closeAlert()
              }
            }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }