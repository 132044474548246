var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "topbar" },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c(
          "div",
          {
            staticClass:
              "row flex-nowrap align-items-center justify-content-between"
          },
          [
            _c("div", { staticClass: "title_mobile" }, [
              _c("h1", { staticClass: "wsnw-sm" }, [_vm._v(_vm._s(this.title))])
            ]),
            _c(
              "div",
              {
                staticClass: "add_fund_mobile d-flex align-items-center top-nav"
              },
              [
                _vm.$store.getters.global.userAgreement
                  ? _c("AgreementPopup", {
                      attrs: {
                        modalOpen: !(
                          this.$route.name === "KnowYourCustomerInformation"
                        )
                      }
                    })
                  : _vm._e(),
                _vm.hideSearchNotif == false
                  ? [
                      _c("Search"),
                      _c("div", { staticClass: "divider ml-20 mr-17" }),
                      _c(
                        "div",
                        { staticClass: "d-flex add-funds mr-15" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-sm add-icon",
                              attrs: { id: _vm.route },
                              on: { click: _vm.openAddFundsModal }
                            },
                            [
                              _c("i", {
                                staticClass: "icon-add fs16 pl-0 pr-10"
                              }),
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(_vm.overview.due, "inr")
                                )
                              )
                            ]
                          ),
                          _vm.overview.due
                            ? _c(
                                "b-popover",
                                {
                                  key: "hover",
                                  staticStyle: {
                                    "z-index": "999",
                                    position: "absolute"
                                  },
                                  attrs: {
                                    target: _vm.route,
                                    placement: "bottom",
                                    title: "Popover!",
                                    "custom-class":
                                      "mw373 position-absolute z-99",
                                    triggers: "hover"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "text-gray-600" }, [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$store.getters.user.prepaid == 0
                                              ? "Total Due"
                                              : "Usable Balance"
                                          ) +
                                          " : " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.overview.due,
                                              "inr"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _c("div", [
                                      _vm._v(" Click "),
                                      _c("i", {
                                        staticClass: "icon-add fs12 pl-3 pr-3"
                                      }),
                                      _vm._v(" to Add Funds ")
                                    ])
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _vm._t("left-button"),
                _vm._t("default"),
                _vm._t("right-button"),
                _vm.buttonText
                  ? [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-sm wsnw fs14 new-order",
                          on: {
                            click: function($event) {
                              return _vm.navigate()
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fs16 pl-0 pr-10",
                            class: this.buttonIconClass
                          }),
                          _vm._v(" " + _vm._s(this.buttonText) + " ")
                        ]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        )
      ]),
      _c(
        "AlertBox",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !(this.$route.name === "KnowYourCustomerInformation") &&
                !(this.$route.name === "KnowYourCustomerDocuments"),
              expression:
                "!(this.$route.name === 'KnowYourCustomerInformation') && !(this.$route.name === 'KnowYourCustomerDocuments')"
            }
          ],
          attrs: { src: _vm.showAlert, variant: "danger" },
          on: {
            removeAlert: function($event) {
              return _vm.removeAlert()
            }
          }
        },
        [
          _c("b-link", { attrs: { to: "/kyc-info" } }, [
            _vm._v("To complete your profile, please fill out KYC form. ")
          ]),
          _c("br"),
          _c("b-link", { attrs: { to: "/support" } }, [
            _vm._v("For any queries, Contact Us")
          ])
        ],
        1
      ),
      _c(
        "AlertBox",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !(this.$route.name === "KnowYourCustomerInformation") &&
                !(this.$route.name === "KnowYourCustomerDocuments"),
              expression:
                "!(this.$route.name === 'KnowYourCustomerInformation') && !(this.$route.name === 'KnowYourCustomerDocuments')"
            }
          ],
          attrs: { src: _vm.showPendingSeller, variant: "warning" }
        },
        [
          _c(
            "span",
            {
              staticClass: "text-white",
              staticStyle: { "font-size": "1rem !important" }
            },
            [_vm._v(" Awaiting Approval from Team Shyplite ")]
          ),
          _c("br"),
          _c("b-link", { attrs: { to: "/support" } }, [
            _vm._v("For any queries, Contact Us")
          ])
        ],
        1
      ),
      _c("BillingAddFunds", { ref: "BillingAddFunds" }),
      _c("Alert", {
        ref: "addfundsalert",
        on: { openAddfunds: _vm.openAddfunds }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }