<template>
  <div class="topbar">
    <div class="container-fluid">
      <div class="row flex-nowrap align-items-center justify-content-between">
        <div class="title_mobile">
          <h1 class="wsnw-sm">{{ this.title }}</h1>
        </div>

        <div class="add_fund_mobile d-flex align-items-center top-nav">
          <AgreementPopup v-if="$store.getters.global.userAgreement" :modalOpen="!(this.$route.name === 'KnowYourCustomerInformation')" />
          <template v-if="hideSearchNotif == false">
            <Search />

            <div class="divider ml-20 mr-17"></div>

            <div class="d-flex add-funds mr-15">
              <b-button :id="route" @click="openAddFundsModal" class="btn-sm add-icon"><i class="icon-add fs16 pl-0 pr-10"></i>{{  overview.due | currency('inr') }}</b-button>

                <b-popover 
                  key="hover"
                  :target="route"
                  placement="bottom"
                  title="Popover!"
                  v-if="overview.due"
                  style="z-index: 999;position: absolute;"
                  custom-class="mw373 position-absolute z-99"
                  triggers="hover">
                    <div class="text-gray-600">
                      <div>
                        {{$store.getters.user.prepaid == 0 ? 'Total Due' : 'Usable Balance'}}  : {{ overview.due | currency('inr') }}
                      </div>
                      <div>
                        Click <i class="icon-add fs12 pl-3 pr-3"></i> to Add Funds
                      </div>
                    </div>
                  </b-popover>
            </div>

          </template>
          <slot name="left-button"></slot>

          <slot></slot>

          <slot name="right-button"></slot>

          <template v-if="buttonText">
            <b-button class="btn-sm wsnw fs14 new-order" @click="navigate()">
              <i :class="this.buttonIconClass" class="fs16 pl-0 pr-10"></i>
              {{ this.buttonText }}
            </b-button>
          </template>


            <!--<NotificationSidebar class="notifications ml-20" />-->
        </div>
      </div>
    </div>

    <AlertBox
      :src="showAlert"
      @removeAlert="removeAlert()"
      variant="danger"
      v-show="!(this.$route.name === 'KnowYourCustomerInformation') && !(this.$route.name === 'KnowYourCustomerDocuments')"
    >
      <b-link to="/kyc-info">To complete your profile, please fill out KYC form. </b-link><br>
      <b-link to="/support">For any queries, Contact Us</b-link>
    </AlertBox>
    <AlertBox
      :src="showPendingSeller"
      variant="warning"
      v-show="!(this.$route.name === 'KnowYourCustomerInformation') && !(this.$route.name === 'KnowYourCustomerDocuments')"
    >
      <span class="text-white" style="font-size: 1rem !important;"> Awaiting Approval from Team Shyplite </span><br>
      <b-link to="/support">For any queries, Contact Us</b-link>
    </AlertBox>
    <BillingAddFunds ref="BillingAddFunds" />
    <Alert @openAddfunds="openAddfunds" ref="addfundsalert" />
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import Search from "@/components/Search.vue";
import AlertBox from "@/components/AlertBox.vue";
import AgreementPopup from "@/components/AgreementPopup.vue";
import NotificationSidebar from "@/components/NotificationSidebar.vue";
import BillingAddFunds from "@/components/BillingAddFunds.vue";
import Alert from "@/components/alert.vue";

import Vue from "vue";
// import VueFreshchat from "vue-freshchat";

// Vue.use(VueFreshchat, { appToken: "d760d5f2-f4aa-433b-abc3-a7abffd1d2ca" });


export default {
  name: "AppTopbar",
  props: {
    title: String,
    hideSearchNotif: Boolean,
    buttonText: String,
    buttonLink: String,
    buttonClass: String,
    buttonIconClass: String,
  },
  components: {
    Search,
    AlertBox,
    Alert,
    NotificationSidebar,
    AgreementPopup,
    BillingAddFunds
  },
  data () {
    return {
      overview: this.$store.getters.global.overview || {},
      userExternalId: this.$store.state.user.id || null,
      userEmail: this.$store.state.user.username || null,
    }
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  created() {
    this.getOverview()
    const data = localStorage.getItem('showaddfunds');
    if (!data) {     // if no value exists associated with the key, return null
        return null;
    }
 
    const item = JSON.parse(data);
 
    // If TTL has expired, remove the item from localStorage and return null
    if (Date.now() > item.ttl) {
        localStorage.removeItem('showaddfunds');
        return null;
    }
  },
  mounted() {
    // this.$freshchat.hideWidget()
    // if (this.$store.state.user.sellerStatus == 1) {
    //   this.$freshchat.showWidget()
    //   this.$freshchat.setExternalId(this.userExternalId);
    //   this.$freshchat.setUserProperties({ email: this.userEmail, sellerId:this.userExternalId });
    // }
    
  },
  methods: {
    openAddfunds({flag}) {
      if (flag) {
        this.$refs.addfundsalert.isOpen = !this.$refs.addfundsalert.isOpen;
        this.openAddFundsModal();
      }
    },
    async getOverview() {
      const res = await this.axios.get('/v3/due')
      this.overview = res.data
      this.$store.commit('setGlobal', {overview: res.data})
      this.$store.commit('setNotifstate', res.data.notificationShow)
      this.$store.commit('setGlobal',{showConsignor : res.data.franchiseService})
      this.$store.commit('setGlobal',{isCodSeller : res.data.isCodSeller})
      this.$store.commit('setGlobal', {notificationCount : res.data.notificationCount})
      const seller = this.$store.state.user;
      const user = res.data.seller
      if (user) {

        seller['profileStatus'] = user.profileStatus;
        seller['status'] = user.status;
        seller['registrationCompleted'] = user.profileStatus==2 ? true : false;
        seller['sellerStatus'] = user.profileStatus!=2 //  (New User)
         || (user.profileStatus==2 && user.status==8) // Disapproved KYC docs
          ? 0 : 
          user.profileStatus==2 && (user.status==9 || user.status ==3) // Pending Approval and KYC approved
          ? 2 : 
          1
        // console.log(registrationCompleted, sellerStatus)
        this.$store.commit('setUser', seller)

      }
      let flag = localStorage.getItem('showaddfunds')
      if (this.$store.state.sellerState == true) {
        this.openAddFundsModal();
      } else {
          if (seller.prepaid == 1 && seller.sellerStatus == 1 && res.data.due < 100 && !flag) {
              const data = {
                  value: true,                  // store the value within this object
                  ttl: Date.now() + (24 * 60 * 60 * 1000),   // store the TTL (time to live)
              }
       
          // store data in LocalStorage 
          this.$refs.addfundsalert.isOpen = !this.$refs.addfundsalert.isOpen;
          localStorage.setItem('showaddfunds', JSON.stringify(data));
        }
      }
      
    },
    searchFocus() {
      this.searchToggle = !this.searchToggle;
    },
    navigate() {
      if (this.buttonLink) {
        this.$store.commit('setTab', 0)
        let newTab = event.metaKey || event.ctrlKey;
        if (newTab) {

          window.open(this.buttonLink);
        }
        else {

          this.$router.push({ path: this.buttonLink });        
        }
        
      }
    },
    openAddFundsModal() {
      this.$refs.BillingAddFunds.isOpen = !this.$refs.BillingAddFunds.isOpen;
      this.userMenuToggle = true;
    },
    goBack() {
      this.$router.go(-1);
    },
    removeAlert() {
      this.$store.commit("setRegistrationCompleted");
    }
  },
  computed: {
    searchFilters() {
      return this.$store.getters.searchFilters;
    },
    searchResults() {
      return this.$store.getters.searchResults;
    },
    showAlert() {
      return this.$store.getters.user.sellerStatus == 0;
    },
    showPendingSeller() {
      return this.$store.getters.user.sellerStatus == 2;
    },
    route() {
      return this.$route.name
    }
  },
};
</script>

<style lang="css">
  .add-icon {
    width : max-content;
  }
  .sellerdue {
    width: max-content;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    height: 2.75rem;
    font-size: 1rem;
    padding-top: 0.8rem;
    padding-left: 0.7rem;
    padding-right: 1rem;
    font-weight: 500;
    background: #fff;
    color: #006eff;
    border: 1px solid #fff;
    box-shadow: 0px 4px 4px rgb(45 55 75 / 10%);
  }
</style>

<style lang="scss">
.topbar {
  position: fixed;
  width: calc(100vw - 72px);
  height: rem(80px);
  display: inline-flex;
  align-items: center;

  background-color: $bg-gray;
  z-index: 10;
  border-bottom: 1px solid $gray-200;

  @include media-breakpoint-down(sm) {
    .top-nav {
      justify-content: end;
    }

    width: 100vw;
    height: rem(70px);
    border-bottom: 0;
    .search {
      display: none;
    }
    .divider {
      display: none;
    }
    .notifications {
      display: none;
    }
    .new-order {
      display: none;
    }
  }
}
</style>
