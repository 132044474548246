<template>
    <b-modal
    centered
    v-model="isOpen"
    title="User Agreement"
    id="userAgreement"
    ref="userAgreement"
    modal-class="pricing-modal"
    no-close-on-backdrop
    >

    <template v-slot:modal-header="{ close }">
        <span class="flex-fill fs18 lh28 fw500 mt-8">
            Agreement Update <span class="text-gray-600 fs12 float-right">v{{ agreement.version }}</span>
        </span>
    </template>

    <template v-slot:default>
        <div class="justify-content-between pt-8 pt-md-26">
            <p v-html="agreement.changes"></p>
        </div>
    </template>

    <template v-slot:modal-footer="{ close }">

        <div>
            <div class="form-group">
                <b-form-checkbox
                v-model="status"
                name="checkbox-1"
                value="accepted"
                :disabled="accepted"
                unchecked-value="not_accepted"
                class="fs12 text-gray-600"
                >
                I accept the <b-link :href="agreement.agreementPath" target="_blank">user agreement</b-link>, updated on {{ agreement.publishedDate | date }}
            </b-form-checkbox>
        </div>
        <div>
            <b-button
            variant="primary"
            :disabled="!status"
            @click="accept"
            v-if="!accepted"
            class="btn-sm"
            >
            <span>Accept</span>
        </b-button>
        <span v-else class="tag success">Accepted <i class="icon-booked"></i></span>
    </div>
</div>

</template>

</b-modal>
</template>

<script>
export default {
    name: "AgreementPopup",
    props: [ "modalOpen" ],
    data() {
        return {
            agreement: {},
            isOpen: this.modalOpen,
            accepted: false,
            status: false,
        };
    },
    created() {
        this.getItems()
    },

    methods: {
        async accept() {
            try {
                const res = await this.axios.post('/acceptAgreement', {agreementID: this.agreement.agreementID})
                if(res.data.status === true) {
                    this.popToast("booked", "Success!", res.data.message)
                    this.$store.commit('setGlobal', {'userAgreement': null})
                    this.accepted = true
                    setTimeout(() => {
                        this.isOpen = false
                    }, 1000)
                }else {
                    this.popToast("failed", "Failed!", res.data.message)
                }

            } catch(e) {
                // statements
                console.log(e);
            }  
        },
        async getItems() {
            try {
                const res = await this.axios.get('/agreementPopup')

                if(res.data.agreementRequired == true) {
                    this.agreement = res.data
                } else {
                    this.$store.commit('setGlobal', {'userAgreement': null})
                    this.accepted = true
                    setTimeout(() => {
                        this.isOpen = false
                    }, 1000)
                }
            } catch(e) {
                this.$store.commit('setGlobal', {'userAgreement': null})
                this.accepted = true
                setTimeout(() => {
                    this.isOpen = false
                }, 1000)
                console.log(e);
            }
        }
    }
};
</script>